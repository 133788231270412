import React, {useEffect, useMemo} from "react";
import type {
    ErrorBoundaryComponent,
    LinksFunction,
    LoaderFunction,
    MetaFunction
} from "@remix-run/node";
import type {CatchBoundaryComponent} from "@remix-run/react/routeModules";
import {json} from "@remix-run/node";
import type {HtmlMetaDescriptor} from "@remix-run/react";
import {
    Link,
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useCatch,
    useFetchers,
    useLoaderData,
    useLocation,
    useTransition
} from "@remix-run/react";
import {ErrorBoundary as SentryErrorBoundary, withSentryRouteTracing} from "@sentry/remix";
import invariant from "tiny-invariant";
import {BeGambleAware, Facebook, Instagram, Telegram, Twitter} from "@bettorsignals/assets";
import {
    CookiesBanner,
    Footer,
    MessageOfTheDay,
    NavBar,
    NProgress,
    OddsFormat,
    ScrollToTopButton,
    UIProvider
} from "@bettorsignals/ui";
import {HotjarScript, GoogleAnalyticsScript, useAnalytics} from "@bettorsignals/tracking";
import {$api} from "~/api";
import type {MessageOfTheDay as MessageOfTheDayType, Settings} from "@bettorsignals/api";
import {Collection, isMessageOfTheDay, isSettings} from "@bettorsignals/api";
import {ErrorMessage} from "~/components";
import {LinkToContext} from "~/contexts/LinkTo.context";
import {LinkTo} from "~/utils/LinkTo";
import tailwindStylesheetUrl from "./styles/style.css";
import packageJson from "../package.json";

export const meta: MetaFunction = ({data}) => {
    if (!data) {
        return {
            title: "There was an error"
        };
    }

    const {
        settings: {defaultWebsiteTitle, defaultMetaDescription}
    } = data as LoaderData;

    const meta: HtmlMetaDescriptor = {
        title: defaultWebsiteTitle,
        description: defaultMetaDescription,
        charset: "utf-8",
        viewport: "width=device-width,initial-scale=1",
        meta: [
            {name: "msapplication-TileColor", content: "#7209B7"},
            {
                name: "msapplication-square150x150logo",
                content: "/_static/favicons/mstile-150x150.png"
            },
            {name: "msapplication-config", content: "none"},
            {name: "theme-color", content: "#FCFDFC"},
            {name: "twitter:card", content: "summary"},
            {name: "twitter:title", content: defaultWebsiteTitle},
            {
                name: "twitter:description",
                content: defaultMetaDescription
            },
            {name: "twitter:site", content: "@BettorSignals"},
            {
                name: "twitter:image",
                content: `${LinkTo.fullPageUrl()}/_static/social-cards/og-square.jpg`
            },
            {property: "og:type", content: "website"},
            {property: "og:title", content: defaultWebsiteTitle},
            {
                property: "og:description",
                content: defaultMetaDescription
            },
            {property: "og:url", content: LinkTo.fullPageUrl()},
            {
                property: "og:image",
                content: `${LinkTo.fullPageUrl()}/_static/social-cards/og-large.jpg`
            }
        ]
    };

    return meta;
};

export const links: LinksFunction = () => {
    return [
        {rel: "dns-prefetch", href: "https://api.bettorsignals.com"},
        {rel: "stylesheet", href: tailwindStylesheetUrl},
        {rel: "stylesheet", href: "https://rsms.me/inter/inter.css"},
        {rel: "shortcut icon", href: "/_static/favicons/favicon.ico"},
        {rel: "mask-icon", href: "/_static/favicons/safari-pinned-tab.svg", color: "#F72585"},
        {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/_static/favicons/favicon-32x32.png"
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/_static/favicons/favicon-16x16.png"
        },
        {
            rel: "apple-touch-icon",
            type: "image/png",
            sizes: "32x32",
            href: "/_static/favicons/apple-touch-icon.png"
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "192x192",
            href: "/_static/favicons/android-chrome-192x192.png"
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "256x256",
            href: "/_static/favicons/android-chrome-256x256.png"
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "512x512",
            href: "/_static/favicons/android-chrome-512x512.png"
        },
        {
            rel: "manifest",
            href: "/_static/site.webmanifest"
        }
    ];
};

interface LoaderData {
    apiUrl: string;
    gaMeasurementId: typeof process.env.PUBLIC_GA_MEASUREMENT_ID;
    hotjarSiteId: typeof process.env.PUBLIC_HOTJAR_SITE_ID;
    messageOfTheDay: MessageOfTheDayType | undefined;
    settings: Settings;
    version: typeof packageJson.version;
}

export const loader: LoaderFunction = async () => {
    const apiUrl = process.env.API_URL;
    invariant(apiUrl, "API_URL environmental variable must be defined");

    const messageOfTheDayRequest = $api.singleton(Collection.MessageOfTheDay).read();
    const settingsRequest = $api.singleton(Collection.Settings).read();

    const [messageOfTheDayResponse, settingsResponse] = await Promise.all([
        messageOfTheDayRequest,
        settingsRequest
    ]);

    const messageOfTheDay = isMessageOfTheDay(messageOfTheDayResponse)
        ? messageOfTheDayResponse
        : undefined;

    invariant(isSettings(settingsResponse), "Error while loading settings data");

    return json<LoaderData>({
        apiUrl,
        gaMeasurementId: process.env.PUBLIC_GA_MEASUREMENT_ID,
        hotjarSiteId: process.env.PUBLIC_HOTJAR_SITE_ID,
        messageOfTheDay,
        settings: settingsResponse,
        version: packageJson.version
    });
};

const App = () => {
    const {apiUrl, gaMeasurementId, hotjarSiteId, messageOfTheDay, version} =
        useLoaderData<LoaderData>();
    const {pathname} = useLocation();
    const {pageView} = useAnalytics();
    const transition = useTransition();
    const fetchers = useFetchers();

    useEffect(() => {
        pageView(pathname);
    }, [pageView, pathname]);

    /**
     * This gets the state of every fetcher active on the app and combine it with the state of the
     * global transition (Link and Form), then use them to determine if the app is idle or if it's loading.
     *
     * We consider both loading and submitting as loading.
     */
    const state = useMemo<"idle" | "loading">(() => {
        const states = [transition.state, ...fetchers.map((fetcher) => fetcher.state)];

        if (states.every((state) => state === "idle")) return "idle";
        return "loading";
    }, [transition.state, fetchers]);

    useEffect(() => {
        if (state === "loading") NProgress.start();
        else if (state === "idle") NProgress.done();
    }, [state, transition.state]);

    return (
        <SentryErrorBoundary>
            <LinkToContext.Provider value={{apiUrl}}>
                <html lang="en">
                    <head>
                        <Meta />
                        <Links />
                        <GoogleAnalyticsScript measurementId={gaMeasurementId} />
                        <HotjarScript siteId={hotjarSiteId} />
                        {/*Sendpulse (traffi.cx)*/}
                        <script
                            src="//web.webformscr.com/apps/fc3/build/loader.js"
                            async
                            sp-form-id="80eadbfc999b2eee53b9df320437a9f364071fdb9f52f700ca231c4b314fe37f"
                        />
                    </head>
                    <body className="mt-[80px] flex min-h-screen w-screen flex-col overflow-x-hidden md:mt-[64px]">
                        <UIProvider
                            currency={"$"}
                            oddsFormat={OddsFormat.American}
                            useLocation={useLocation}
                            Link={({to, ...rest}) =>
                                typeof to === "string" && to.startsWith("http") ? (
                                    <a href={to} {...rest} />
                                ) : (
                                    <Link to={to} {...rest} />
                                )
                            }
                        >
                            <NavBar
                                menuItems={[
                                    {
                                        name: "NFL Previews",
                                        href: "/nfl/previews",
                                        withLabel: true
                                    },
                                    // {
                                    //     name: "Bookmakers",
                                    //     href: LinkTo.bookmakersPage(),
                                    //     withLabel: false
                                    // },
                                    {
                                        name: "Bonuses",
                                        href: LinkTo.bonusesPage(),
                                        withLabel: false
                                    },
                                    {
                                        name: "Blog",
                                        href: LinkTo.blogPage(),
                                        withLabel: false
                                    }
                                ]}
                                contents={[
                                    {
                                        text: "Want more free tips?"
                                    },
                                    {
                                        text: "Grab them on our social media.",
                                        className: "text-bs-gradient-primary"
                                    }
                                ]}
                                socialMediaIcons={[
                                    {
                                        Icon: Twitter,
                                        url: LinkTo.twitterPage()
                                    },
                                    {
                                        Icon: Instagram,
                                        url: LinkTo.instagramPage()
                                    },
                                    {
                                        Icon: Telegram,
                                        url: LinkTo.telegramChannel()
                                    },
                                    {
                                        Icon: Facebook,
                                        url: LinkTo.facebookGroup()
                                    }
                                ]}
                            />
                            {messageOfTheDay?.isVisible ? (
                                <MessageOfTheDay
                                    message={messageOfTheDay.message}
                                    href={messageOfTheDay.href}
                                    speed={messageOfTheDay.speed}
                                />
                            ) : undefined}

                            <Outlet />
                            <ScrollRestoration />
                            <Scripts />
                            <LiveReload port={3334} />

                            <div id="push-sticky-footer" className="flex flex-1" />

                            <Footer
                                version={version}
                                domain={"BettorSignals.com"}
                                heading={"Follow us on our social media and grab extra bonuses!"}
                                subHeading={
                                    "Follow BettorSignals.com to make sure you never miss the best tips from our experts!"
                                }
                                internalLinkGroups={[
                                    {
                                        label: "Pages",
                                        links: [
                                            {
                                                name: "Bonuses",
                                                href: LinkTo.bonusesPage()
                                            },
                                            {
                                                name: "Blog",
                                                href: LinkTo.blogPage()
                                            },
                                            {
                                                name: "About",
                                                href: LinkTo.aboutUsPage()
                                            }
                                        ]
                                    },
                                    {
                                        label: "Legal",
                                        links: [
                                            {
                                                name: "Disclaimer",
                                                href: LinkTo.disclaimerPage()
                                            },
                                            {
                                                name: "Privacy Policy",
                                                href: LinkTo.privacyPolicyPage()
                                            },
                                            {
                                                name: "Terms & Conditions",
                                                href: LinkTo.termsAndConditionsPage()
                                            }
                                        ]
                                    },
                                    {
                                        label: "Partners",
                                        links: [
                                            {
                                                name: "betsense.ai",
                                                href: "https://www.betsense.ai/"
                                            }
                                        ]
                                    }
                                ]}
                                social={[
                                    {
                                        name: "Twitter",
                                        href: LinkTo.twitterPage(),
                                        Icon: Twitter
                                    },
                                    {
                                        name: "Instagram",
                                        href: LinkTo.instagramPage(),
                                        Icon: Instagram
                                    },
                                    {
                                        name: "Telegram",
                                        href: LinkTo.telegramChannel(),
                                        Icon: Telegram
                                    },
                                    {
                                        name: "Facebook",
                                        href: LinkTo.facebookGroup(),
                                        Icon: Facebook
                                    }
                                ]}
                                externalLinks={[
                                    {
                                        name: "BeGambleAware",
                                        href: "https://www.begambleaware.org/",
                                        Icon: BeGambleAware
                                    }
                                ]}
                            />
                            <CookiesBanner
                                message="This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies."
                                learnMoreButton={{
                                    text: "Learn more",
                                    href: LinkTo.privacyPolicyPage()
                                }}
                                closeButton={{text: "Close"}}
                            />
                            <ScrollToTopButton />
                        </UIProvider>
                    </body>
                </html>
            </LinkToContext.Provider>
        </SentryErrorBoundary>
    );
};

export default withSentryRouteTracing(App);

export const CatchBoundary: CatchBoundaryComponent = () => {
    const caught = useCatch();
    console.log(`caught:`, caught);

    return (
        <html>
            <head>
                <title>Page Not Found</title>
                <Meta />
                <Links />
            </head>
            <body className="flex min-h-screen w-screen overflow-x-hidden">
                <ErrorMessage statusCode={404} />
                <Scripts />
            </body>
        </html>
    );
};

export const ErrorBoundary: ErrorBoundaryComponent = (props) => {
    console.error("ErrorBoundary error:", props.error);

    return (
        <html>
            <head>
                <title>Oh no! Something went wrong...</title>
                <Meta />
                <Links />
            </head>
            <body className="flex min-h-screen w-screen overflow-x-hidden">
                <ErrorMessage statusCode={500} />
                <Scripts />
            </body>
        </html>
    );
};
