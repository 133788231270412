import React from "react";
import {Link} from "@remix-run/react";
import {ArrowLongRight} from "@bettorsignals/assets";
import {WithBackgroundGradient} from "@bettorsignals/ui";
import type {ErrorMessageProps} from "./ErrorMessage.props";
import {LinkTo} from "~/utils/LinkTo";

import errorImage404Source from "./assets/error-image-404.png";
import errorImage500Source from "./assets/error-image-500.png";

const ERRORS = {
    404: {
        illustration: errorImage404Source,
        heading: "404 Not Found",
        subheading: "The page you requested could not be found..."
    },
    500: {
        illustration: errorImage500Source,
        heading: "Sorry, something went wrong :(",
        subheading: "Our team is looking at this issue..."
    }
} as const;

const ErrorMessage: React.FC<ErrorMessageProps> = ({statusCode}) => {
    const {illustration, heading, subheading} = ERRORS[statusCode];

    return (
        <WithBackgroundGradient>
            <div className="mx-auto mb-20 flex h-fit flex-col gap-5 md:mb-24 md:flex-row md:gap-12 md:px-4">
                <div className="flex flex-none items-center justify-center md:items-start">
                    <img
                        className="h-full w-full max-w-[271px] md:max-h-[522px] md:max-w-[368px]"
                        src={illustration}
                        alt={`Error ${statusCode} illustration`}
                    />
                </div>
                <div className="flex flex-col items-center justify-center gap-4 px-4 md:gap-6">
                    <h1 className="text-center text-3xl font-extrabold leading-9 text-gray-900 lg:max-w-[562px] lg:text-5xl lg:leading-none">
                        {heading}
                    </h1>

                    <p className="text-center text-lg font-normal leading-8 text-gray-500 lg:max-w-[499px] lg:text-xl lg:leading-7">
                        {subheading}
                    </p>

                    <Link
                        to={LinkTo.homePage()}
                        className="mt-4 flex w-full max-w-[190px] items-center justify-center gap-3.5 rounded-md border border-bs-pink bg-bs-pink py-2 text-white shadow-sm transition-colors duration-100 ease-in-out hover:bg-white hover:text-bs-pink"
                    >
                        <span className="text-base font-medium leading-6">Go to homepage</span>

                        <span className="h-4 w-4">
                            <ArrowLongRight />
                            <span className="sr-only">Arrow pointing right</span>
                        </span>
                    </Link>
                </div>
            </div>
        </WithBackgroundGradient>
    );
};

export default ErrorMessage;
